import React from "react"
import * as styles from "./pcViewer.module.css"
import Layout from "components/common/layout.js"
import SettingLayout from "components/member/Setting/settingLayout"
import pcViewerBackground from "images/pcViewerBackground.png"
import { navigate } from "gatsby"
import { getThumb, download } from "utils"
const pcViewer = () => {
  return (
    <Layout settingHeader navCancel headerStyle={{ width: 134.59, height: 30 }}>
      <SettingLayout title="PC 뷰어 다운로드" />
      <div>
        <img src={pcViewerBackground} />
      </div>
      <div
        className={styles.con}
        style={{
          cursor: "pointer",
        }}
      >
        <div
          onClick={() => {
            alert("다운로드 진행 중입니다.")
            download(process.env.SETUP, "peach_seoga_setup.zip")
          }}
        >
          피치서가 PC 뷰어 다운로드
        </div>
      </div>
      <div
        className={styles.con}
        style={{
          cursor: "pointer",
        }}
      >
        <div
          onClick={() =>
            {
              alert("다운로드 진행 중입니다.")
            download(process.env.SETUP, "피치서가사용법시안10.pdf")
          }
          }
        >
          피치서가 PC 뷰어 이용가이드 다운로드 (PDF)
        </div>
      </div>
      <div className={styles.conBig}>
        <div>
          <div>PC 뷰어 소개</div>
          <div>
            <li>
              데스크톱, 노트북 등 PC에서 피치서가를 이용하는 프로그램입니다.
            </li>
            <li>
              콘텐츠를 읽고, 보관하고, 탐색하는 등 웹과 동일한 경험을
              제공합니다.
            </li>
            <li>
              스토리를 읽고, 보고, 듣고, 쓰기의 편의성을 제공하기 위해
              개발하였습니다.
            </li>
          </div>
        </div>

        <div>
          <div>안내 사항</div>
          <div>
            <li>
              해외에서 사용할 경우 현지 사정에 따라 서비스 이용이 제한될 수
              있어요.
            </li>
            <li>피치서가 PC 뷰어는 Window 운영체제에서 이용할 수 있어요. </li>
          </div>
        </div>
      </div>
      <div className={styles.conBig}>
        <div>
          <div>지원 기기 사양 확인하기</div>
          <div>
            <li>피치서가 PC 뷰어는 Windows - OS 10 이상 지원합니다.</li>
          </div>
        </div>
      </div>
      <div className={styles.info}>
        <div>PC 뷰어 설치가 어려우세요?</div>

        <div>
          <div>
            피치서가 PC뷰어 이용가이드를 따라해 보세요.
            <br /> 그래도 어려우면 고객센터에 문의해 주세요.
          </div>
        </div>
        <div>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => navigate("/cs/contact/list/")}
          >
            고객센터에 문의하기
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default pcViewer
